// src/pages/Contact.tsx
import React, { useState } from 'react';

const Contact: React.FC = () => {
  const [formStatus, setFormStatus] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setFormStatus('SUCCESS');
    // Here you can implement form submission logic using a third-party service as discussed earlier.
  };

  return (
    <>
      <h1>Contact Cybitron</h1>
      {formStatus === 'SUCCESS' ? (
        <p>Thank you! Your message has been sent.</p>
      ) : (
        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="name">Name:</label><br />
            <input type="text" name="name" id="name" required />
          </div>
          <div>
            <label htmlFor="email">Email:</label><br />
            <input type="email" name="email" id="email" required />
          </div>
          <div>
            <label htmlFor="message">Message:</label><br />
            <textarea name="message" id="message" required />
          </div><br />
          <button type="submit">Submit</button>
        </form>
      )}
    </>
  );
};

export default Contact;

