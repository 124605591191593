import React from 'react';
import { Outlet } from 'react-router-dom';
import './MainLayout.css';
//import videoFile from '../assets/videos/istockphoto-1315388795-640_adpp_is.mp4';
import videoFile from '../assets/videos/iStock-1315388795.mp4';


const MainLayout: React.FC = () => (
    <div className="layout-container">
        <div className="video-background">
            <video autoPlay loop muted playsInline>
                <source src={videoFile} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </div>
        <div className="overlay-wrapper">
            <div className="overlay-content">
                <Outlet />
            </div>
        </div>
    </div>
);

export default MainLayout;
