// src/pages/About.tsx
import React from 'react';

const About: React.FC = () => (
  <>
    <h1>About Cybitron</h1>
    <p>
      Cybitron is dedicated to advancing hardware technology and security solutions. With a team of experts in hardware systems, security architectures, and distributed ledger technologies, we deliver innovative solutions that meet the evolving needs of various industries.
    </p>
    <h2>Our Mission</h2>
    <p>
      To provide cutting-edge hardware and security solutions that empower our clients to achieve their goals with confidence and efficiency.
    </p>
    <h2>Our Team</h2>
    <p>
      Our multidisciplinary team combines expertise in engineering, security, and technology development to create solutions that are both innovative and practical.
    </p>
  </>
);

export default About;

