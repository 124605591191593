import React from 'react';

const Home: React.FC = () => (
    <>
        <h1>Welcome to Cybitron</h1>
        <p>
            At Cybitron, we pioneer the intersection of technology and engineering, creating
            innovative solutions that drive trust and progress.
        </p>
    </>
);

export default Home;
