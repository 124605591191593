// Example for HardwareSystems.tsx
import React from 'react';

const HardwareSystems: React.FC = () => (
  <>
    <h1>Advanced Hardware Systems</h1>
    <p>
      Our cutting-edge hardware-in-the-loop testing and real-time rendering solutions
      ensure that our clients receive unparalleled performance and reliability.
    </p>
  </>
);

export default HardwareSystems;

