// src/components/Header.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import logo from '../assets/images/cybitron_logo_small.png';

const Header: React.FC = () => (
  <header className="header">
    <div className="logo-container">
      <Link to="/">
        <img src={logo} alt="Cybitron Logo" className="logo" />
      </Link>
      <span className="tagline">Bridging Trust in the Digital Frontier</span>
    </div>
    <nav>
      <ul className="nav-links">
        <li><Link to="/">Home</Link></li>
        <li><Link to="/hardware-systems">Hardware Systems</Link></li>
        <li><Link to="/security-solutions">Security Solutions</Link></li>
        <li><Link to="/distributed-ledger">Distributed Ledger</Link></li>
        <li><Link to="/about">About Us</Link></li>
        <li><Link to="/contact">Contact</Link></li>
      </ul>
    </nav>
  </header>
);

export default Header;

