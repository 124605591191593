import React from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import MainLayout from './components/MainLayout';

// Page Components
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import DistributedLedger from './pages/DistributedLedger';
import HardwareSystems from './pages/HardwareSystems';
import SecuritySolutions from './pages/SecuritySolutions';

import './App.css';

const App: React.FC = () => (
    <Router>
        <Header />

        
        <Routes>
            <Route element={<MainLayout />}>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/distributed-ledger" element={<DistributedLedger />} />
                <Route path="/hardware-systems" element={<HardwareSystems />} />
                <Route path="/security-solutions" element={<SecuritySolutions />} />
            </Route>
        </Routes>
        <Footer />
    </Router>
);

export default App;
