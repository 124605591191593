// src/pages/SecuritySolutions.tsx
import React from 'react';

const SecuritySolutions: React.FC = () => (
  <>
    <h1>Integrated Security Solutions</h1>
    <p>
      Security is at the core of everything we do at Cybitron. Our integrated security solutions are designed to protect hardware assets and data in today's complex threat landscape.
    </p>
    <h2>Tokenized Hardware Security</h2>
    <p>
      We implement tokenization and advanced cryptographic techniques to secure hardware components, preventing unauthorized access and tampering.
    </p>
    <h2>Zero Trust Architectures</h2>
    <p>
      Our zero trust models ensure that every user, device, and connection is authenticated and authorized, minimizing the risk of security breaches.
    </p>
  </>
);

export default SecuritySolutions;

